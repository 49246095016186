<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="pixinventLink"
      href="#">NetLaminat - Versiyon {{currentApplicationVersion}}</a>

    <span class="d-none d-sm-inline-block">, Her Hakkı Saklıdır.</span>
  </p>

</footer>
<!--Footer Ends-->
